/* eslint-disable camelcase */
import { css } from '@emotion/core';

import { derivativeStandardCommon } from '@client/utils/styles';

export const $menuList = ({ colors, typography }) => [
  typography.family.circular,
  typography.size.XS,
  css`
  color: ${colors.ui.greyDark[300]};
  margin: 5px;
  display: flex;
  justify-content: space-between;
  `
];

export const $optionFirstRow = css`
  display: flex;
  justify-content: space-between;
`;
export const $toolTipWrapper = css`
  text-align: left;
  padding: 8px;
  line-height: 1.5;
  font-size: 16px;
  & p {
    margin: 4px 2px;
  }
  & span {
  margin-left: 4px;
  }
`;

export const $optionSecondRow = css`
  margin-bottom: 10px;
`;

export const $optionLabel = ({ colors, typography }) => [
  typography.size.M,
  css`
  max-width: 50%;
  font-weight: 700;
  color: ${colors.ui.darkBlue[700]};
`];

export const $optionLabelDetail = ({ colors, typography }) => [
  typography.family.circular,
  typography.size.S,
  css`
    color: ${colors.ui.grey[500]};
    font-weight: 700;
  `
];

export const $optionBody = ({ colors, typography }) => [
  typography.family.circular,
  typography.size.S,
  css`
  display: flex;
  color: ${colors.ui.greyDark[500]};
  `
];

export const $optionDesc = css`
  font-size: 14px;
`;

export const $optionDescWithRating = css`
  width: 90%;
  font-size: 14px;
`;

export const $optionConfidenceRating = ({ colors, typography }) => [
  typography.family.circular,
  typography.size.S,
  css`
  display: flex;
  justify-content: center;
  width: 10%;
`];

export const $optionDivider = css`
  margin-right: 7px;
  margin-left: 7px;
`;

export const $checkbox = css`
  margin-bottom: 5px;
`;

export const $standardContainer = css`
  .standard-container__value-container { 
    position: initial;

    [role=tooltip] {
      white-space: normal;
      margin-top: 18px;
    }

    [role=button] {
      border-bottom: unset;
    }
  }
  .standard-container__multi-value {
  }
`;

export const $derivativeStandards = (isChip) => ({ colors }) => {
  const backgroundColor = isChip ? colors.ui.greyDark[500] : colors.ui.coreBlue[500];
  const fontSize = isChip ? '7px' : '12px';
  return [
    derivativeStandardCommon(),
    css`
    background-color: ${backgroundColor};
    font-size: ${fontSize};
    margin-right: 5px;
  `
  ];
};
