import { createStore, reducer, thunk, thunkOn, action, computed } from 'easy-peasy';
import { createBrowserHistory } from 'history';
import { get, isEmpty } from 'lodash-es';
import { createReduxHistoryContext, reachify } from 'redux-first-history';

// Sub-stores
import { apps } from './schema';
import bundles from '../bundles/store';
import forms from '../forms/store';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory({
    basename: process.env.CLIENT_BASE_PATH
  }),
  reduxTravelling: process.env.NODE_ENV !== 'production'
});

// Add entities to the store
export const store = createStore({
  // Model! This contains the state tree, actions, thunks, listeners, and computed props.
  appName: 'default',
  isReadOnly: false, // Safe by default, set to false to enable editing.
  isPreview: true, // Preview is true when loading a content preview. It's true
  // by default to prevent a flash of editor-facing components when loading previews.
  isAppLoaded: false, // Gets set to true once initialize() has finished.
  globalLoadingSpinner: {
    loadingState: false,
    label: 'Loading content'
  },
  // Routing and history.
  router: reducer(routerReducer),
  setGlobalLoadingSpinner: action((state, payload) => {
    state.globalLoadingSpinner = { ...payload };
  }),

  // The global loading spinner displays while saving certain content.
  setGlobalLoadingSpinnerOnSave: thunkOn(
    (actions, storeActions) => storeActions.saveStatus.setStatus,
    async (actions, target) => {
      const { selectFieldType, isSaving, spinnerLabel } = target.payload;
      const fieldsToDisplayLoadingSpinner = ['tags'];
      const displaySpinnerOnSaveRules = [
        fieldsToDisplayLoadingSpinner.includes(selectFieldType)
      ];

      if (isSaving === true && displaySpinnerOnSaveRules.some((rule) => rule)) {
        actions.setGlobalLoadingSpinner({ loadingState: true, label: spinnerLabel });
      } else if (isSaving === false) {
        actions.setGlobalLoadingSpinner({ loadingState: false });
      }
    }),

  // Current User.
  user: {
    data: {},

    isAuthenticated: computed((state) => {
      return !isEmpty(state.data);
    }),

    isWireUser: computed((state) => state.data.hasWireAccess),
    isAdmin: computed((state) => state.data.isWireAdmin),
    isStaff: computed((state) => state.data.isStaff),
    isContributor: computed((state) => state.data.isContributor),
    role: computed((state) => {
      if (state.data.isWireAdmin) {
        return 'Newsela Wire Admin';
      }

      if (state.data.isContributor) {
        return 'Contributor';
      }

      if (state.data.isStaff) {
        return 'Newsela Staff';
      }

      return 'Other';
    }),
    permissions: computed((state) => state.data.permissions),
    // User Actions
    addData: action((state, payload) => {
      state.data = payload;
    })
  },

  // When we look at content in the SingleBundle and SingleArticle containers,
  // we want to be able to access some very lightweight information about the given
  // content across the app, without having to refetch it.
  // We set it when we render these components, and then reset it when we unmount them.
  content: {
    data: {},

    set: action((state, payload) => {
      state.data = payload;
    })
  },

  rootStreams: {
    // Root Streams are an array of Stream IDs that the root content we're editing
    // is connected to. These will be inherited by child content when they're created,
    // and will be inherited by attached content if that content isn't in any
    // other streams.
    data: [],

    set: action((state, payload) => {
      state.data = payload;
    })
  },

  saveStatus: {
    // Normally, if there are no errors or warnings, clicking the publish button
    // will publish the content. However, if there are warnings, the first time
    // the user clicks 'Publish' we do NOT actually publish. Instead, we toggle
    // this flag to true, and change the button text to 'Confirm Publish', allowing
    // the user to double check their work before clicking the 'Publish' button again.
    attemptedPublish: false,
    isPublishing: false,
    isUnpublishing: false,
    isSubmittingDraft: false,
    isArchiving: false,
    isUnarchiving: false,
    isEditing: false,
    isSaving: false,
    isValidating: false,
    isUploadingFile: false,
    isLoadingArticle: false,
    // If any status that represent an action being executed is true,
    // the 'isPerformingAction' is set to true to disable the buttons,
    // and avoid executing concurrent actions.
    isPerformingAction: false,
    updatedAt: null,
    errors: [],

    // Save Status Actions
    setStatus: action((state, payload) => {
      // Set status from payload items if they're defined
      state.attemptedPublish = get(payload, 'attemptedPublish', state.attemptedPublish);
      state.isPublishing = get(payload, 'isPublishing', state.isPublishing);
      state.isUnpublishing = get(payload, 'isUnpublishing', state.isUnpublishing);
      state.isSubmittingDraft = get(payload, 'isSubmittingDraft', state.isSubmittingDraft);
      state.isArchiving = get(payload, 'isArchiving', state.isArchiving);
      state.isUnarchiving = get(payload, 'isUnarchiving', state.isUnarchiving);
      state.isEditing = get(payload, 'isEditing', state.isEditing);
      state.isSaving = get(payload, 'isSaving', state.isSaving);
      state.isValidating = get(payload, 'isValidating', state.isValidating);
      state.isUploadingFile = get(payload, 'isUploadingFile', state.isUploadingFile);
      state.isLoadingArticle = get(payload, 'isLoadingArticle', state.isLoadingArticle);
      state.updatedAt = get(payload, 'updatedAt', state.updatedAt);
      state.errors = get(payload, 'errors', state.errors);

      state.isPerformingAction = Object.keys(state)
        // isEditing and isPerformingAction are special cases, and should not disable the buttons.
        .filter((key) => !['isEditing', 'isPerformingAction'].includes(key) && key.startsWith('is'))
        .some((key) => state[key]);
    }),

    clearStatus: action((state) => {
      state.attemptedPublish = false;
      state.isPublishing = false;
      state.isUnpublishing = false;
      state.isSubmittingDraft = false;
      state.isArchiving = false;
      state.isUnarchiving = false;
      state.isEditing = false;
      state.isSaving = false;
      state.isValidating = false;
      state.isUploadingFile = false;
      state.isLoadingArticle = false;
      state.updatedAt = null;
      state.errors = [];
      state.isPerformingAction = false;
    })
  },

  // Used to prevent concurrent editing on Article Levels.
  articleLevelsConcurrency: {
    data: {},

    setLevelsUpdatedAt: action((state, levels) => {
      levels.forEach(({ uid, lastEditor }) => {
        if (state.data[uid]?.locked === true) {
          return;
        }
        const levelLastEditor = JSON.parse(lastEditor);
        if (levelLastEditor) {
          if (state.data.tabId === levelLastEditor.tabId || state.data[uid]?.tabId === levelLastEditor.tabId) {
            // This level is being edited by the same user, or they just acquired the lock from the backend.
            // Either way we update the the state with the new tabId and updatedAt values.
            state.data[uid] = { ...levelLastEditor, locked: false };
          } else {
            // Not the same user and they were not granted the lock.
            if (!state.data[uid]) {
              state.data[uid] = { ...levelLastEditor, locked: false };
            } else {
              // The user tab is older than the latest updatedAt value of this level, and since we already know they are not the last editor, means their version is old.
              state.data[uid] = { ...levelLastEditor, updatedAt: null, locked: true };
            }
          }
        } else {
          state.data[uid] = { tabId: state.data.tabId, updatedAt: null, locked: false };
        }
      });
    }),

    setUniquePageId: action((state, id) => {
      state.data.tabId = id;
    })
  },

  // Editor pane / drawers.
  drawer: {
    data: {},
    loadingSpinner: {
      loadingState: false,
      label: 'Loading content'
    },

    // The current drawer's id is used to determine whether Outline items
    // are selected in the Bundles app.
    currentId: computed((state) => {
      return state.data.id;
    }),

    // Drawer Actions.
    open: action((state, payload) => {
      state.data = {
        ...payload
      };
    }),

    close: action((state) => {
      state.data = {};
    }),

    switch: thunk(async (actions, payload) => {
      actions.close();
      actions.open(payload);
    }),

    setLoadingSpinner: action((state, payload) => {
      state.loadingSpinner = { ...payload };
    }),
  },

  filters: {
    data: {},
    set: action((state, payload) => {
      state.data = {
        ...payload
      };
    }),
    clear: action((state) => {
      state.data = {};
    })
  },

  // Root Actions
  setAppName: action((state, payload) => {
    state.appName = payload;
  }),

  setReadOnly: action((state, payload) => {
    state.isReadOnly = payload;
  }),

  setPreview: action((state, payload) => {
    state.isPreview = payload;
  }),

  setAppLoaded: action((state, payload) => {
    state.isAppLoaded = payload;
  }),

  // Root Listeners
  onLocationChange: thunkOn(
    () => '@@router/LOCATION_CHANGE',
    async (actions, target, { getStoreActions }) => {
      // Routing changes the appName. The name -> title + icon + color mappings
      // exist in the common/containers/Header component
      const matches = target.payload.location.pathname.match(/^\/([\w-]+)\/?/);
      const app = matches && matches[1];
      // Array of apps coming from the schema
      const currentApps = Object.keys(apps);

      // Checks if current app exists in the schema
      if (currentApps.includes(app)) {
        actions.setAppName(app);
      } else {
        getStoreActions().forms.validation.disable();
        actions.setAppName('default');
      }

      if (target.payload.location.pathname.match(/preview$/)) {
        actions.setPreview(true);
      } else {
        actions.setPreview(false);
      }
    }
  ),

  powerWords: [],
  setPowerWords: action((state, payload) => {
    state.powerWords = payload;
  }),

  vocabLevels: [],
  setVocabLevels: action((state, payload) => {
    state.vocabLevels = payload;
  }),

  definition: {},
  setDefinition: action((state, payload) => {
    state.definition = payload;
  }),

  // Sub-models
  bundles,
  forms
}, {
  // Configuration for the store! This contains...configuration!
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [routerMiddleware]
});

// Expose the history to the Router component in App.jsx
export const history = reachify(createReduxHistory(store));
