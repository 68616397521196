import React, { forwardRef } from 'react';

import { faFile } from '@fortawesome/pro-regular-svg-icons';
import { Button } from '@newsela/angelou';
import Tooltip from 'mineral-ui/Tooltip';

// Temporary workaround: The AISvgFile component is not currently available in Angelou,
// so we're manually using the raw SVG here. This approach is sufficient for now and does not
// introduce any significant scalability issues.
const AISvgFile = () => {
  return (
    <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M2.87937 4.00347C2.87937 4.00347 2.25241 5.49739 2.04379 5.71064C1.83518 5.9239 0.376012 6.5625 0.376012 6.5625C0.376012 6.5625 1.83349 7.20515 2.04154 7.41898C2.24959 7.63281 2.87261 9.12847 2.87261 9.12847C2.87261 9.12847 3.77735 7.63493 3.98597 7.42167C4.19458 7.20842 5.37597 6.56943 5.37597 6.56943C5.37597 6.56943 4.19627 5.92717 3.98822 5.71334C3.78017 5.49951 2.87937 4.00347 2.87937 4.00347Z' fill='white' />
      <g opacity='0.5'>
        <path d='M1.87628 0.298905C1.87628 0.298905 1.49999 1.19546 1.37479 1.32344C1.24959 1.45142 0.373896 1.83469 0.373896 1.83469C0.373896 1.83469 1.24855 2.22033 1.3734 2.34865C1.49825 2.47697 1.87212 3.37454 1.87212 3.37454C1.87212 3.37454 2.4151 2.47822 2.5403 2.35023C2.6655 2.22225 3.3745 1.83875 3.3745 1.83875C3.3745 1.83875 2.66654 1.45334 2.54169 1.32502C2.41684 1.1967 1.87628 0.298905 1.87628 0.298905Z' fill='white' />
      </g>
      <g opacity='0.8'>
        <path d='M5.83811 1.00203C5.83811 1.00203 5.47154 1.87547 5.34958 2.00015C5.22761 2.12483 4.37449 2.4982 4.37449 2.4982C4.37449 2.4982 5.22662 2.87393 5.34826 2.99894C5.4699 3.12396 5.83415 3.99842 5.83415 3.99842C5.83415 3.99842 6.36312 3.1252 6.48509 3.00052C6.60706 2.87584 7.29777 2.50225 7.29777 2.50225C7.29777 2.50225 6.60805 2.12674 6.48641 2.00172C6.36477 1.87671 5.83811 1.00203 5.83811 1.00203Z' fill='white' />
      </g>
      <path fillRule='evenodd' clipRule='evenodd' d='M7.82178 18.2678C6.71721 18.2678 5.82178 17.3724 5.82178 16.2678V5.74609C5.82178 4.64153 6.71721 3.74609 7.82178 3.74609H16.2566C17.3611 3.74609 18.2566 4.64153 18.2566 5.74609V6.83305V16.2678C18.2566 17.3724 17.3611 18.2678 16.2566 18.2678H7.82178ZM7.82178 16.2678L16.2566 16.2678L16.2566 6.83305V5.74609L7.82178 5.74609V16.2678ZM9.43051 7.3984C8.87822 7.3984 8.43051 7.84612 8.43051 8.3984C8.43051 8.95068 8.87822 9.3984 9.43051 9.3984H14.6479C15.2002 9.3984 15.6479 8.95068 15.6479 8.3984C15.6479 7.84612 15.2002 7.3984 14.6479 7.3984H9.43051ZM8.43051 11.0068C8.43051 10.4546 8.87822 10.0068 9.43051 10.0068H14.6479C15.2002 10.0068 15.6479 10.4546 15.6479 11.0068C15.6479 11.5591 15.2002 12.0068 14.6479 12.0068H9.43051C8.87822 12.0068 8.43051 11.5591 8.43051 11.0068ZM9.43051 12.6157C8.87822 12.6157 8.43051 13.0634 8.43051 13.6157C8.43051 14.1679 8.87822 14.6157 9.43051 14.6157H14.6479C15.2002 14.6157 15.6479 14.1679 15.6479 13.6157C15.6479 13.0634 15.2002 12.6157 14.6479 12.6157H9.43051Z' fill='white' />
    </svg>
  );
};

const ForwardSVGRef = forwardRef(AISvgFile);

const LevelerButton = ({ item, levelerStatus, fetchLeveledArticle, leveledData }) => {
  return (
    <Tooltip content={item.title}>
      <Button
        __cssFor={{
          root: {
            backgroundColor: '#0B1386',
            padding: '12.5px', // Almost like 14px but accounts for minor layout shifts due to Angelou's Button native behavior.
            display: 'flex',
            alignItems: 'center',
            borderRadius: '8px !important',
            marginLeft: '8px',
            marginRight: '4px'
          }
        }}
        icon={{
          SvgComponent: ForwardSVGRef,
          alignment: Button.iconAlignments.left,
        }}
        disabled={levelerStatus.isLevelerDisabled || leveledData.loading}
        onClick={() => {
          fetchLeveledArticle();
        }}
      >
        {item.overrides.buttonText}
      </Button>
    </Tooltip>
  );
};

export const menuLeveler = [{
  title: 'Levels the article. Each text can be leveled up to three times; after that, provide a new text.',
  overrides: {
    buttonText: 'Level Text',

  },
  content: faFile,
  customButton: LevelerButton,
}];
