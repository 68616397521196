import React from 'react';

import { LoadingShimmer } from '@newsela/angelou';
import PropTypes from 'prop-types';

import { $metaCurrentVal } from '@client/forms/inputs/ArticleLevelsSelector/style';
import { getTarget } from '@client/utils/lexile';
import { NO_LEXILE, NO_RAW_LEXILE } from '@shared/constants';

import {
  $levelMetaData,
  $current,
  $heading,
  $target
} from './style';

const NOT_AVAILABLE = 'N/A';

export default function LexileWordCount ({ formData }) {
  const {
    rawLexile,
    lexile,
    wordCount,
    gradeBand,
    isOriginalLevel,
    lexile_level: leveledLexileLevel,
    word_count: leveledWordCount,
  } = formData || {};

  const renderComparedTextWordCount = () => (
    <>
      <div css={$levelMetaData}>
        {
          !formData
            ? <LoadingShimmer __css={{
              height: '30px',
              width: '80px'
            }}
              />
            : (
              <p css={$current}>
                <span css={$heading}>Lexile:</span>
                <span css={$metaCurrentVal(rawLexile, gradeBand, 'rawLexile', isOriginalLevel, leveledLexileLevel)}>
                  {leveledLexileLevel
                    ? leveledLexileLevel === NO_RAW_LEXILE
                      ? NO_LEXILE
                      : `${leveledLexileLevel}L`
                    : NOT_AVAILABLE}
                </span>
              </p>
              )
        }
      </div>
      <div css={$levelMetaData}>
        <p css={$current}>
          {!formData
            ? <LoadingShimmer __css={{
              height: '30px',
              width: '80px'
            }}
              />
            : (
              <>
                <span css={$heading}>Word Count:</span>
                <span css={$metaCurrentVal(leveledWordCount, gradeBand, 'wordCount', isOriginalLevel, leveledLexileLevel)}>
                  {leveledWordCount ?? NOT_AVAILABLE}
                </span>
              </>
              )}
        </p>
      </div>
    </>
  );

  const renderOriginalTextWordCount = () => {
    return (
      <>
        <div css={$levelMetaData}>
          <p css={$current}><span css={$heading}>Lexile:</span> <span css={$metaCurrentVal(rawLexile, gradeBand, 'rawLexile', isOriginalLevel)}>{lexile || NO_LEXILE}</span></p>
          <p css={$target}><span css={$heading}>Target:</span> <span>{getTarget(gradeBand, 'rawLexile', formData.isOriginalLevel)}</span></p>
        </div>
        <div css={$levelMetaData}>
          <p css={$current}><span css={$heading}>Word Count:</span> <span css={$metaCurrentVal(wordCount, gradeBand, 'wordCount', isOriginalLevel)}>{wordCount}</span></p>
          <p css={$target}><span css={$heading}>Target:</span> <span>{getTarget(gradeBand, 'wordCount', isOriginalLevel)}</span></p>
        </div>
      </>
    );
  };

  return (
    leveledLexileLevel || !formData
      ? renderComparedTextWordCount()
      : renderOriginalTextWordCount()
  );
}

LexileWordCount.propTypes = {
  /** Full form data */
  formData: PropTypes.object
};
