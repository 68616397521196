import React, { useEffect } from 'react';

import { ToggleButton } from '@newsela/angelou';
import Tooltip from 'mineral-ui/Tooltip';

const ToggleLeveler = ({ item, levelerStatus, setLevelerStatus, formData, leveledData }) => {
  const label = !levelerStatus.toggleLeveledText ? 'Show leveled text' : 'Hide leveled text';
  const isOnNewLevel = levelerStatus.leveledGradeBand !== formData.gradeBand;
  const isToggleDisabled = levelerStatus.isToggleLevelerDisabled || isOnNewLevel;

  useEffect(() => {
    if (isOnNewLevel || leveledData.error) {
    // Added delay to prevent conflicts during Prosemirror (un)mounting.
    // It's a technical workaround for race conditions and harmless, though it may become obsolete later.
      const DELAY_TO_CLOSE = 300;
      setTimeout(() => {
        setLevelerStatus((prevState) => ({
          ...prevState,
          toggleLeveledText: false
        }));
      }, DELAY_TO_CLOSE);
    }
  }, [isOnNewLevel, leveledData.error]);

  return (
    <Tooltip content={item.title}>
      <ToggleButton
        label={label}
        defaultPressed={false}
        disabled={isToggleDisabled}
        pressed={levelerStatus.toggleLeveledText}
        onClick={() => {
          setLevelerStatus((prevState) => {
            return { ...prevState, toggleLeveledText: !prevState.toggleLeveledText };
          });
        }}
        css={{
          marginLeft: '8px',
          marginRight: '12px',
        }}
      />
    </Tooltip>
  );
};

export const menuToggleLeveler = [
  {
    title: 'Compare with generated text',
    customButton: ToggleLeveler,
  },
];
