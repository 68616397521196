import { ENVIRONMENT_FLAGS } from '@client/utils/constants';

export function isLocalEnv (hostname) {
  return (hostname.includes('localhost'));
}

export function isDevEnv (hostname) {
  // alexandria.dev.newseladev.com
  const devOrStagingEnv = /^alexandria.dev.newseladev.com/g;
  return (devOrStagingEnv.test(hostname));
}

export function isStagingEnv (hostname) {
  // alexandria.staging.newseladev.com
  const devOrStagingEnv = /^alexandria.staging.newseladev.com/g;
  return (devOrStagingEnv.test(hostname));
}

export function isFeatureEnv (hostname) {
  // feature-branch.dev.newseladev.com (NOT alexandria.dev.newseladev.com)
  const featureEnv = /\b(?!(?:alexandria)\b)(?!(?:app)\b)\w+[\w-]+\.dev\.newseladev\.com/g;
  return (featureEnv.test(hostname));
}

export function getEnvironmentFlags (hostname) {
  if (isLocalEnv(hostname)) {
    return ENVIRONMENT_FLAGS.local;
  } else if (isFeatureEnv(hostname)) {
    return ENVIRONMENT_FLAGS.feature;
  } else if (isStagingEnv(hostname)) {
    return ENVIRONMENT_FLAGS.staging;
  } else if (isDevEnv(hostname)) {
    return ENVIRONMENT_FLAGS.dev;
  }
}
