import { createRef } from 'react';

import { constants } from '@newsela/angelou';
import cogoToast from 'cogo-toast';

// We're exposing a subset of the cogo-toast options, and we're making sure that
// the colors are consistent with angelou's status colors

const cogoOptions = {
  bar: {
    size: '4px',
    style: 'solid'
  }
};

const methodColors = {
  success: constants.colors.status.success[500],
  info: constants.colors.status.info[500],
  loading: constants.colors.status.primary[500],
  warn: constants.colors.status.warning[500],
  error: constants.colors.status.danger[500]
};

// Exported for testing
export function getOptions (options = {}, method = 'info') {
  const opts = {
    ...cogoOptions,
    hideAfter: options.hideAfter ? options.hideAfter : options.persist ? 0 : 5,
    heading: options.heading || ''
  };

  opts.bar.color = methodColors[method];
  return opts;
}

function toast (method, message, options) {
  const res = cogoToast[method](message, {
    ...getOptions(options, method),
    // Allow passing an onClick function into the toast. Note: Only use this
    // in specific circumstances where the toast message specifies that clicking
    // will do something other than hiding the toast (our default behavior).
    onClick: () => {
      if (options?.onClick) {
        options.onClick();
      }
      res.hide();
    }
  });

  return res;
}

export function success (message, options) {
  return toast('success', message, options);
}

export function info (message, options) {
  return toast('info', message, options);
}

export function loading (message, options) {
  return toast('loading', message, options);
}

export function warn (message, options) {
  return toast('warn', message, options);
}

export function error (children, options) {
  return toast('error', children, options);
}

export const globalToasterRef = createRef();
