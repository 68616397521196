import { useCallback, useEffect, useState } from 'react';

import { TARGET_LEXILE, LANG_EN, NUMERICAL_GRADE_BANDS } from '@client/utils/constants';
import { fetchJSON } from '@client/utils/rest';
import { error } from '@client/utils/toast';

function findHighestGradeBand (obj) {
  const gradeOrder = {
    GRADE_K: 0, // Assign a numeric value to K for comparison purposes
    ...NUMERICAL_GRADE_BANDS
  };

  return obj.articleLevels?.reduce((highest, level) => {
    const highestValue = gradeOrder[highest] || -1; // Default to -1 for invalid grades
    const currentValue = gradeOrder[level.gradeBand] || -1;
    return currentValue > highestValue ? level.gradeBand : highest;
  }, 'GRADE_K'); // Initialize with the lowest grade
}
function shouldDisableLeveler ({ language, parent, gradeBand, isOriginalLevel, levelAttempts, text }) {
  const MAX_LEVEL_ATTEMPTS = 3;

  if (language !== LANG_EN) {
    return true;
  }

  const isTextInsufficientForLeveling = !text || text.length < 2;
  const hasReachedLevelingLimit = levelAttempts >= MAX_LEVEL_ATTEMPTS;

  if (hasReachedLevelingLimit || isTextInsufficientForLeveling) {
    return true;
  }

  const highestGradeBandSupported = findHighestGradeBand(parent);
  const isCurrentGradeHighest = highestGradeBandSupported === gradeBand;
  const isOriginalOrGrade12 = isOriginalLevel || gradeBand === 'GRADE_12';
  return isCurrentGradeHighest || isOriginalOrGrade12;
}

const useLeveler = ({ gradeBand, rawText, text, isOriginalLevel, language }, parent) => {
  const [levelerStatus, setLevelerStatus] = useState({
    isLevelerDisabled: shouldDisableLeveler({
      language,
      parent,
      gradeBand,
      isOriginalLevel
    }),
    isToggleLevelerDisabled: true,
    toggleLeveledText: false,
    leveledText: null,
    levelAttempts: 0
  });

  const [leveledData, setLeveledData] = useState({
    data: null,
    loading: false,
    error: null,
  });

  const convertBandToRange = (grade) => {
    // Seti is presently only leveling grades up to 10 and no less than 2.
    const SETI_GRADE_MAPPING = {
      GRADE_12: 'GRADE_10',
      GRADE_11: 'GRADE_10',
      GRADE_1: 'GRADE_2',
      GRADE_K: 'GRADE_2'
    };

    const gradeForSeti = SETI_GRADE_MAPPING[grade] || grade;

    const range = TARGET_LEXILE[gradeForSeti];
    return range && range.length === 2 ? `${range[0]}-${range[1]}L` : null;
  };

  const range = convertBandToRange(gradeBand);

  if (!range) setLeveledData((prev) => ({ ...prev, error: `Can't level the grade band: ${gradeBand}` }));

  const fetchLeveledArticle = useCallback(async () => {
    setLevelerStatus((prevState) => {
      return {
        ...prevState,
        isToggleLevelerDisabled: false,
        toggleLeveledText: true,
        leveledGradeBand: gradeBand
      };
    });
    setLevelerStatus((prev) => {
      if (prev.leveledText === text) {
        return { ...prev, leveledText: text, levelAttempts: prev.levelAttempts + 1 };
      } else {
        return { ...prev, leveledText: text, levelAttempts: 0 };
      }
    });

    setLeveledData({ data: null, loading: true, error: null });

    try {
      const body = JSON.stringify({
        original_text: { format: 'TEXT_RAW', raw_text: rawText },
        targets: [{ lexile: range }]
      });
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body
      };
      const levelerURL = `${process.env.SETI_BASE_URL}/leveler/level-article`;
      const res = await fetchJSON(levelerURL, options);
      setLeveledData({ data: res, loading: false, error: null });
    } catch (err) {
      setLeveledData({ data: null, loading: false, error: 'An error happened while leveling. If the error persists, please reach out to #content-production.' });
      setLevelerStatus((prevState) => {
        const newState = {
          ...prevState,
          isToggleLevelerDisabled: true,
          toggleLeveledText: false,
        };

        return newState;
      });
    }
  }, [rawText]);

  useEffect(() => {
    setLevelerStatus((prevState) => ({
      ...prevState,
      isLevelerDisabled: shouldDisableLeveler({
        language,
        parent,
        gradeBand,
        isOriginalLevel,
        levelAttempts: levelerStatus.levelAttempts,
        text
      }),
    }));

    if (levelerStatus.leveledText !== text) {
      setLevelerStatus((prevState) => ({
        ...prevState,
        levelAttempts: 0
      }));
    }
  }, [gradeBand, text, levelerStatus.levelAttempts, levelerStatus.leveledText]);

  useEffect(() => {
    if (leveledData.error) {
      error(leveledData.error, {
        persist: true,
        onClick: () => {
          setLeveledData((prevState) => {
            return {
              ...prevState,
              error: null,
            };
          });
        }

      });
    }
  }, [leveledData.error]);

  return {
    fetchLeveledArticle,
    leveledData,
    levelerStatus,
    setLevelerStatus,
    setLeveledData
  };
};

export default useLeveler;
