/* eslint-disable camelcase */
import { css } from '@emotion/core';

import { getLexileOrWordCountColor, getVariantColor } from '@client/utils/styles';

export const $addLevelWrapper = css`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: 30vh;
  justify-content: center;
  width: 100%;
`;

export const $lexileGradeBand = ({ colors }) => css`
  color: ${colors.ui.green[300]};
  font-weight: 700;
`;

export const $levelMeta = css`
  align-items: flex-start;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  width: 100%;
`;

export const $metaCurrent = css`
  margin: 0;
`;

// Change the color to show if it's within the expected range.
// Note that the original level doesn't have an expected range.
// This is also used in the LexileWordCount component.
export const $metaCurrentVal = (val, gradeBand, type, isOriginalLevel, leveledLexileLevel) => ({ colors }) => {
  if (isOriginalLevel) {
    return css`font-weight: 700;`;
  }

  const color = getLexileOrWordCountColor(val, gradeBand, type, colors, leveledLexileLevel);

  return css`
    color: ${color};
    font-weight: 700;
  `;
};

export const $metaTarget = ({ colors }) => css`
  color: ${colors.ui.greyLight[300]};
  margin: 5px 0 0;
`;

export const $metaTargetVal = ({ colors }) => css`
  color: ${colors.ui.greyLight[500]};
`;

export const $levelSelector = ({ colors }) => css`
  align-items: center;
  border-bottom: 1px solid ${colors.ui.greyLight[300]};
  display: flex;
  justify-content: center;
  margin: -40px 0 20px 0;
  padding: 20px 0;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${colors.ui.white[500]}
`;

export const $languageSelector = (variant) => ({ colors }) => {
  const variantColor = variant ? getVariantColor(variant) : colors.ui.coreBlue[500];
  const lightVariantColor = getVariantColor(variant, 'light');
  return css`
    background-color: ${lightVariantColor || colors.ui.white[500]};
    &:hover, &:focus, &:focus:hover {
      border-color: ${variantColor};
      background-color: ${lightVariantColor || colors.ui.greyExtraLight[100]};
    }
    border-color: ${variant ? variantColor : null};
    margin-right: 20px;
    &:focus-visible {
      outline: ${variantColor} auto 1px;
    }
    &:focus {
      box-shadow: none;
    }
  `;
};

export const $languageDropdown = css`
  & [role=menuitem] span {
    align-items: center;
  }
  & [role=menuitem] span svg {
    padding-bottom: 1px;
  }
`;

export const $languageItem = (variant) => ({ colors }) => {
  const backgroundColor = variant ? getVariantColor(variant, 'light') : colors.ui.greyExtraLight[100];
  return css`:hover { background-color: ${backgroundColor}; }`;
};

// Override hardcoded height in mineral-ui button.
export const $selectorButton = (variant) => ({ colors }) => {
  const backgroundColor = variant ? getVariantColor(variant, 'light') : colors.ui.greyExtraLight[100];
  const borderColor = variant ? getVariantColor(variant, 'dark') : colors.ui.coreBlue[500];

  return css`
    margin: 0 1px;
    height: auto;
    border-color: transparent !important;
    border-bottom: 4px solid;
    color: ${colors.ui.coreBlue[500]} !important;
    background-color: ${colors.ui.white[500]};

    &:focus-visible {
      outline: ${borderColor} auto 1px;
    }

    &:hover:not(:focus):not(:active):not([aria-checked=true]):not([disabled]),
    &:active:not([aria-checked=true]):not([disabled]),
    &[aria-checked=true], &[aria-checked=true]:hover {
      background-color: ${backgroundColor};
    }

    &[aria-checked=true], &[aria-checked=true]:hover {
      border-bottom: 4px solid ${borderColor} !important;
    }
  `;
};

export const $selectorButtonInner = css`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  line-height: 1;
  padding: 10px;
`;

export const $selectorGrade = css`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const $selectorLexile = css`
  font-weight: 400;
`;
